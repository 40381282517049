import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Gender } from '@suzy/shared/data-access/suzy-sdk';
import {
  ISignupUser,
  SignupService
} from '../../../../views/anonymous/signup/signup.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import * as moment from 'moment';

@Component({
  selector: 'get-to-know-you',
  templateUrl: './get-to-know-you.component.html',
  styleUrls: ['./get-to-know-you.component.scss']
})
export class GetToKnowYouComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  getToKnowYouForm: UntypedFormGroup;
  submitted = false;
  Gender = Gender;
  user: ISignupUser;
  minBirthdate: NgbDateStruct = { year: 1910, month: 1, day: 1 };
  submitting = false;
  isLoading: boolean;
  el_wrapper: HTMLElement;
  ethnicities: Array<any>;
  educations: Array<any>;
  isNewSignupQuestions = false;
  age: number;

  private storage: Storage;

  get showAgeVerificationCheckbox(): boolean {
    return this.age >= 13 && this.age <= 16;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private signupService: SignupService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.storage = localStorage;
    this.user = this.signupService.getUser();
    this.isNewSignupQuestions =
      this.launchDarklyService.getCWDT2172SignUpQuestionsFlag();

    this.createForm();
    this.route.data.subscribe(data => {
      this.ethnicities = data.ethnicities;
    });
    this.route.data.subscribe(data => {
      this.educations = data.educationOptions;
    });

    this.subscribeToFormChanges();
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }
  subscribeToFormChanges(): void {
    this.getToKnowYouForm.get('birthdate').valueChanges.subscribe(value => {
      this.onBirthdateChange(value);
    });
  }

  onBirthdateChange(birthdate: string): void {
    this.age = moment().diff(birthdate, 'years');
    const acceptTermsControl = this.getToKnowYouForm.get('age_consent_given');
    acceptTermsControl.reset();
    if (this.showAgeVerificationCheckbox) {
      acceptTermsControl.setValidators(Validators.requiredTrue);
    } else {
      this.clearControlValidators(acceptTermsControl);
    }
  }

  clearControlValidators(control: AbstractControl | null): void {
    control?.setValidators(null);
    control?.setErrors(null);
  }

  createForm(): void {
    if (this.isNewSignupQuestions) {
      this.getToKnowYouForm = this.fb.group({
        birthdate: [undefined, Validators.required],
        gender: ['', Validators.required],
        ethnicity_id: ['', Validators.required],
        education_id: ['', Validators.required],
        age_consent_given: [false]
      });
    } else {
      this.getToKnowYouForm = this.fb.group({
        first_name: [this.user.first_name, Validators.required],
        last_name: [this.user.last_name, Validators.required],
        email: [this.user.email, [Validators.required, Validators.email]]
      });
    }
  }

  fieldErrors(name: string): any {
    const control = this.getToKnowYouForm.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  returnToEmailCreate(): void {
    const promoKeys = this.auth.getCurrentPromoKeys();
    const promoToken: string = promoKeys ? promoKeys['promoToken'] : undefined;
    this.router.navigate(['/auth/account-create-email'], {
      queryParams: { promotion_token: promoToken }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.getToKnowYouForm.valid) {
      const account = this.getToKnowYouForm.value;
      this.isLoading = true;

      if (this.isNewSignupQuestions) {
        this.signupService.setUser({
          ...this.user,
          birthdate: account.birthdate,
          gender: account.gender,
          ethnicity_id: account.ethnicity_id,
          education_id: account.education_id,
          progressStatus: 45,
          age_consent_given: account.age_consent_given
        });
      } else {
        this.signupService.setUser({
          ...this.user,
          first_name: account.first_name,
          last_name: account.last_name,
          email: account.email,
          progressStatus: 45
        });
      }

      this.router.navigate(['auth', 'about-yourself']);
    }
  }
}
