import { Injectable } from '@angular/core';
import { MissionKind } from '@suzy/shared/data-access/suzy-sdk';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  completedMissionsIndex: any = [];
  missions: any = [];
  currentMissionIndex: number = 0;
  missionCompleted$ = new Subject<any>();
  missionPointsEarned$ = new Subject<any>();
  reCaptchaAction$ = new Subject<boolean>();

  constructor() {}

  getNextMission(missionId: string): any {
    let selectedMissions = this.missions;
    let nextMission: any;

    let remainingMissions = this.getRemainingMissions(
      missionId,
      selectedMissions
    );

    nextMission = remainingMissions?.find(item => item !== undefined);

    if (nextMission?.['isNotSupported']) {
      let isMissionAvaiable = remainingMissions.find(
        item => item?.isNotSupported === false
      );
      if (isMissionAvaiable) {
        nextMission = isMissionAvaiable;
      } else {
        this.missionCompleted$.next({
          status: 'reset'
        });
      }
    }

    return nextMission;
  }

  getRemainingMissions(currentMissionId: any, selectedMissions: any): any {
    let remainingMissions: Array<any> = [];
    let index = selectedMissions.findIndex(mission => {
      return mission.mission_id === currentMissionId;
    });

    //check for 12 missions starting with currentMissionIndex and restarting if currentMissionIndex reaches last item
    for (let count = 0; count < selectedMissions.length; count++) {
      if (
        selectedMissions[index + 1] &&
        !(
          selectedMissions[index + 1]['answer_status'] === 'completed' ||
          selectedMissions[index + 1]['answer_status'] === 'skipped'
        )
      ) {
        remainingMissions.push(selectedMissions[index + 1]);
      }
      index++;
      if (index >= selectedMissions.length) {
        index = -1;
      }
    }

    return remainingMissions;
  }

  clearCompletedMissionsIndex(): void {
    this.completedMissionsIndex = [];
  }

  addCompletedMissionIndex(item): void {
    let alreadyExists: boolean = false;
    for (let i = 0; i < this.completedMissionsIndex.length; i++) {
      if (this.completedMissionsIndex[i].index === item.index) {
        alreadyExists = true;
        break;
      }
    }
    if (!alreadyExists) {
      this.completedMissionsIndex.push(item);
    }
  }

  getMissionBrandId(missionId: string): string {
    let brandId;
    if (missionId) {
      brandId = this.missions.find(x => x.mission_id === missionId)?.brand_id;
    }

    return brandId;
  }

  getCurrentBrandId(): string {
    return this.missions[this.currentMissionIndex]?.brand_id;
  }

  getCurrentMissionId(): string {
    return this.missions[this.currentMissionIndex]?.mission_id;
  }
}
