<div class="wrapper" #wrapper>
  <app-general-header viewKind="signup"></app-general-header>

  <section class="anonymous" aria-label="main" role="main" id="main-content">
    <article
      class="flex flex-col w-full px-4 m-auto mt-6 max-w-auth sm:max-w-sm-auth"
    >
      <div
        *ngIf="!isNewSignupQuestions"
        class="flex overflow-hidden text-xs rounded progress-bar bg-gray"
      >
        <div
          [ngStyle]="{ width: user.progressStatus + '%' }"
          class="flex flex-col justify-center text-center text-white shadow-none whitespace-nowrap bg-blue-dark"
        ></div>
      </div>
      <div class="center-column">
        <h1
          class="mt-6 mb-8 text-4xl font-bold text-center text-light-black dark:text-white sm:text-3xl"
        >
          {{
            !isNewSignupQuestions
              ? ('gettingToKnowYou.letsGetToKnowYou' | translate)
              : ('gettingToKnowYou.tellUsAboutYourself' | translate)
          }}
        </h1>
        <p
          class="mt-6 mb-6 text-center subtitle text-light-black dark:text-white"
        >
          {{
            !isNewSignupQuestions
              ? ('gettingToKnowYou.letsGetToKnowYouDescription' | translate)
              : ('gettingToKnowYou.tellUsAboutYourselfDescription' | translate)
          }}
        </p>

        <form [formGroup]="getToKnowYouForm" (ngSubmit)="onSubmit()">
          <ng-container *ngIf="!isNewSignupQuestions">
            <div class="mb-6 form-row">
              <label class="text-light-black dark:text-white">
                {{ 'profile.firstName' | translate }}
              </label>

              <div #firstName>
                <input
                  type="text"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  formControlName="first_name"
                  autocomplete="given-name"
                  placeholder="{{ 'profile.firstName' | translate }}"
                  data-track="member-sign-up-first-name"
                />
              </div>
              <small
                *ngIf="fieldErrors('first_name')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('first_name').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>
            <div class="mb-6 form-row">
              <label class="text-light-black dark:text-white">
                {{ 'profile.lastName' | translate }}
              </label>

              <div #lastName>
                <input
                  type="text"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  formControlName="last_name"
                  placeholder="{{ 'profile.lastName' | translate }}"
                  data-track="member-sign-up-last-name"
                />
              </div>
              <small
                *ngIf="fieldErrors('last_name')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('last_name').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>

            <div class="mb-6 form-row">
              <label class="text-light-black dark:text-white">
                {{ 'auth.emailAddress' | translate }}
              </label>

              <div #email>
                <input
                  type="text"
                  class="blockkk w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  formControlName="email"
                  placeholder="{{ 'auth.yourEmailAddress' | translate }}"
                  data-track="member-sign-up-user-email"
                />
              </div>
              <small
                *ngIf="fieldErrors('email')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('email').email">
                  {{ 'auth.pleaseEnterAValidEmailAddress' | translate }}
                </span>
                <span *ngIf="fieldErrors('email').server">
                  {{ fieldErrors('email').server }}
                </span>
                <span *ngIf="fieldErrors('email').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>
          </ng-container>
          <ng-container *ngIf="isNewSignupQuestions">
            <div class="mb-6 form-row">
              <label
                for="aboutBirthday"
                class="text-light-black dark:text-white"
              >
                {{ 'gettingToKnowYou.yourBirthdate' | translate }}
              </label>

              <div #birthday class="relative datepicker form-floating">
                <input
                  type="date"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  placeholder="MM/DD/YYYY"
                  name="dp"
                  formControlName="birthdate"
                  data-track="member-sign-up-birthday"
                />
              </div>
              <div *ngIf="showAgeVerificationCheckbox" class="mt-3">
                <div
                  class="relative flex items-center gap-4 check-container age-confirmation-checkbox-container"
                  [class.age-checkbox-error]="fieldErrors('age_consent_given')"
                >
                  <input
                    type="checkbox"
                    class="styled-checkbox"
                    name=""
                    formControlName="age_consent_given"
                    id="check-1"
                    data-track="member-login-keep-sign-up-btn"
                  />
                  <label
                    class="custom-checkbox text-light-black dark:text-white flex items-center gap-2"
                    for="check-1"
                    style="font-size: 14px"
                  >
                    {{ 'gettingToKnowYou.invalidAgeLabel' | translate }}
                  </label>
                </div>
                <small
                  *ngIf="fieldErrors('age_consent_given')"
                  class="form-text text-muted danger"
                >
                  <span *ngIf="fieldErrors('age_consent_given')?.required">
                    {{ 'gettingToKnowYou.agreeToContinue' | translate }}
                  </span>
                </small>
              </div>

              <small
                *ngIf="fieldErrors('birthdate')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('birthdate').required">
                  {{ 'gettingToKnowYou.birthdateRequired' | translate }}
                </span>
              </small>
            </div>

            <div #gender class="mb-6">
              <label class="text-light-black dark:text-white">
                {{ 'profile.genderPreference' | translate }}
              </label>
              <div class="custom-select">
                <select
                  formControlName="gender"
                  data-track="member-sign-up-gender"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  aria-label="Default select example"
                >
                  <option value="">
                    {{ 'general.selectOne' | translate }}
                  </option>
                  <option value="{{ Gender.female }}">
                    {{ 'gettingToKnowYou.female' | translate }}
                  </option>
                  <option value="{{ Gender.male }}">
                    {{ 'gettingToKnowYou.male' | translate }}
                  </option>
                </select>
              </div>
              <small
                *ngIf="fieldErrors('gender')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('gender').server">
                  {{ fieldErrors('gender').server }}
                </span>
                <span *ngIf="fieldErrors('gender').required">
                  {{ 'general.makeSelection' | translate }}
                </span>
              </small>
            </div>

            <div #race class="mb-6">
              <label class="text-light-black dark:text-white">
                {{ 'gettingToKnowYou.yourRace' | translate }}
              </label>
              <div class="custom-select">
                <select
                  formControlName="ethnicity_id"
                  data-track="member-sign-up-ethnicity_id"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  aria-label="Default select example"
                >
                  <option value="">
                    {{ 'general.selectOne' | translate }}
                  </option>
                  <option
                    *ngFor="let ethnicity of ethnicities"
                    [value]="ethnicity.id"
                  >
                    {{ ethnicity.name | translate }}
                  </option>
                </select>
              </div>
              <small
                *ngIf="fieldErrors('ethnicity_id')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('ethnicity_id').server">
                  {{ fieldErrors('ethnicity_id').server }}
                </span>
                <span *ngIf="fieldErrors('ethnicity_id').required">
                  {{ 'general.makeSelection' | translate }}
                </span>
              </small>
            </div>

            <div class="mb-6">
              <label class="text-light-black dark:text-white">
                {{ 'gettingToKnowYou.education' | translate }}
              </label>
              <div class="custom-select">
                <select
                  formControlName="education_id"
                  data-track="member-sign-up-ethnicity_id"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                >
                  <option value="">
                    {{ 'general.selectOne' | translate }}
                  </option>
                  <option
                    *ngFor="let education of educations"
                    [value]="education.id"
                  >
                    {{ education.name | translate }}
                  </option>
                </select>
              </div>
              <small
                *ngIf="fieldErrors('education_id')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('education_id').server">
                  {{ fieldErrors('ethnicity_id').server }}
                </span>
                <span *ngIf="fieldErrors('education_id').required">
                  {{ 'general.makeSelection' | translate }}
                </span>
              </small>
            </div>
          </ng-container>

          <section class="flex justify-center mt-6 space-x-8 text-center">
            <button
              type="button"
              class="text-black action-btn dark:text-white max-w-38"
              (click)="returnToEmailCreate()"
            >
              {{ 'auth.back' | translate }}
            </button>
            <button
              type="submit"
              (click)="onSubmit()"
              [class.next-button]="isNewSignupQuestions"
              data-track="member-sign-up-continue"
              class="relative pl-4 pr-4 font-bold text-white rounded-full bg-blue-dark h-14 action-btn"
              [disabled]="isLoading"
            >
              {{ 'general.next' | translate }}
              <div class="absolute left-8 button-spinner top-7">
                <suzy-spinner
                  *ngIf="isLoading"
                  size="small"
                  button="true"
                  [round]="isNewSignupQuestions ? '8' : '10'"
                  [color]="'#ffffff'"
                  [overlay]="false"
                >
                </suzy-spinner>
              </div>
            </button>
          </section>
        </form>
      </div>
    </article>
  </section>
  <section class="footer-contianer">
    <app-general-footer id="main-footer"></app-general-footer>
  </section>
</div>
