import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Gender } from '@suzy/shared/data-access/suzy-sdk';
import { ISignupUser, SignupService } from '../signup.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import * as moment from 'moment/moment';

@Component({
  selector: 'about-your-self',
  templateUrl: './about-your-self.component.html',
  styleUrls: ['./about-your-self.component.scss']
})
export class AboutYourSelfComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  aboutYourSelfForm: UntypedFormGroup;
  model: NgbDateStruct;
  submitted = false;
  Gender = Gender;
  stateOptions = [];
  user: ISignupUser;
  minBirthdate: NgbDateStruct = { year: 1910, month: 1, day: 1 };
  isLoading: boolean;
  el_wrapper: HTMLElement;

  employmentOptions: Array<any>;
  relationshipOptions: Array<any>;
  householdOptions: Array<any>;
  parentingOptions: Array<any>;
  ethnicities: Array<any>;
  isNewSignupQuestions = false;
  age: number;

  get showAgeVerificationCheckbox(): boolean {
    return this.age >= 13 && this.age <= 16;
  }

  private storage: Storage;

  constructor(
    private fb: UntypedFormBuilder,
    private signupService: SignupService,
    private router: Router,
    private route: ActivatedRoute,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.storage = localStorage;
    this.user = this.signupService.getUser();
    this.stateOptions = this.route.snapshot.data.states;
    this.isNewSignupQuestions =
      this.launchDarklyService.getCWDT2172SignUpQuestionsFlag();
    this.createForm();

    this.route.data.subscribe(data => {
      this.employmentOptions = data.employmentOptions;
      this.householdOptions = data.householdOptions;
      this.parentingOptions = data.parentingOptions;
      this.ethnicities = data.ethnicities;
      this.relationshipOptions = data.relationshipOptions;
    });

    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    this.aboutYourSelfForm.get('birthdate').valueChanges.subscribe(value => {
      this.onBirthdateChange(value);
    });
  }

  onBirthdateChange(birthdate: string): void {
    this.age = moment().diff(birthdate, 'years');
    const acceptTermsControl = this.aboutYourSelfForm.get('age_consent_given');
    acceptTermsControl.reset();
    if (this.showAgeVerificationCheckbox) {
      acceptTermsControl.setValidators(Validators.requiredTrue);
    } else {
      this.clearControlValidators(acceptTermsControl);
    }
  }

  clearControlValidators(control: AbstractControl | null): void {
    control?.setValidators(null);
    control?.setErrors(null);
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  createForm(): void {
    if (this.isNewSignupQuestions) {
      this.aboutYourSelfForm = this.fb.group({
        employment_id: ['', Validators.required],
        relationship_id: ['', Validators.required],
        household_id: ['', Validators.required],
        parenting_id: ['', Validators.required]
      });
    } else {
      this.aboutYourSelfForm = this.fb.group({
        birthdate: [undefined, Validators.required],
        gender: [Gender.female, Validators.required],
        ethnicity_id: ['', Validators.required],
        age_consent_given: [false]
      });
    }
  }

  returnToGetToKnowYou(): void {
    this.router.navigateByUrl('/auth/get-to-know-you');
  }

  fieldErrors(name: string): any {
    const control = this.aboutYourSelfForm.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.aboutYourSelfForm.valid) {
      const account = this.aboutYourSelfForm.value;
      this.isLoading = true;

      if (this.isNewSignupQuestions) {
        this.signupService.setUser({
          ...this.user,
          employment_id: account.employment_id,
          relationship_id: account.relationship_id,
          household_id: account.household_id,
          parenting_id: account.parenting_id,
          progressStatus: 65
        });
      } else {
        this.signupService.setUser({
          ...this.user,
          birthdate: account.birthdate,
          gender: account.gender,
          ethnicity_id: account.ethnicity_id,
          age_consent_given: account.age_consent_given,
          progressStatus: 65
        });
      }

      this.router.navigate(['auth', 'almost-there']);
    }
  }
}
