import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Represents a request to update account information associated
 * with the current Verisoul session.
 */
export interface AccountUpdateRequest {
  id: string;
  email: string;
}

/**
 * Represents the Verisoul Browser SDK, contained within the global window object.
 */
export interface VerisoulSdk {
  /**
   * Gets a promise that resolves once Verisoul collects a minimum amount of
   * session information to make a prediction.
   *
   * @returns A promise that resolves with the current session ID once Verisoul has
   *         collected enough information to make a prediction.
   */
  session: () => Promise<{ session_id: string }>;

  /**
   *
   * @param request The account update request.
   * @returns A promise that resolves once the account has been updated.
   */
  account: (request: AccountUpdateRequest) => Promise<void>;

  /**
   * Reinitializes the Verisoul SDK, clearing the current session.
   *
   * @returns A promise that resolves once the Verisoul SDK has been reinitialized.
   */
  reinitialize: () => Promise<void>;
}

/**
 * The Verisoul service is responsible for initializing the Verisoul SDK and
 * establishing a session on login and clearing the session on logout.
 */
@Injectable({
  providedIn: 'root'
})
export class VerisoulService {
  /** This subject is used to ensure the Verisoul SDK is only accessed once loaded */
  private isLoaded$: Promise<boolean>;

  /** This subject contains the most recent Verisoul session ID */
  private sessionId$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Initializes the Verisoul Service.
   */
  initialize(verisoulBundleUri: string, verisoulProjectId: string) {
    // Verisoul recommends their script be loaded asynchronously
    this.isLoaded$ = new Promise<boolean>((resolve, reject) => {
      if (this.document.getElementById('verisoul-snippet')) {
        resolve(true);
      } else {
        let script = this.document.createElement('script');
        script.id = 'verisoul-snippet';
        script.type = 'text/javascript';
        script.src = verisoulBundleUri;
        script.setAttribute('verisoul-project-id', verisoulProjectId);
        script.onload = () => {
          console.debug('Verisoul SDK loaded');
          resolve(true);
        };
        script.onerror = (e: any) => {
          console.error('Verisoul SDK load failed', e);
          reject(false);
        };
        this.document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  /**
   * Get the current Verisoul session ID.
   * @returns The current Verisoul session ID, or undefined if not available.
   */
  getSessionId(): string | undefined {
    return this.sessionId$.getValue();
  }

  /**
   * Call this method when a user logs in to establish a session with Verisoul.
   * @returns An observable that emits the session ID once it is available.
   */
  onLogin({
    user_id,
    email
  }: {
    user_id?: string;
    email?: string;
  }): Subject<string | undefined> {
    // https://docs.verisoul.ai/docs/get-session-and-reinitialize
    if (!this.isLoaded$) {
      return;
    }
    this.isLoaded$.then(() => {
      if (window === undefined || window['Verisoul'] === undefined) {
        return;
      }

      const verisoulSdk = window['Verisoul'] as VerisoulSdk;

      verisoulSdk
        .session()
        .then(({ session_id }) => {
          // Verisoul recommends sending the user's email and account ID if available
          if (email && user_id) {
            verisoulSdk
              .account({
                id: user_id,
                email: email
              })
              .catch((e: any) => {
                console.debug('Error updating account with Verisoul', e);
              })
              .finally(() => {
                // If we're updating their account information, wait to notify about the session
                // until that is completed
                this.sessionId$.next(session_id);
              });
          } else {
            this.sessionId$.next(session_id);
          }
        })
        .catch((e: any) => {
          console.debug('Error getting session id from Verisoul', e);
        });
    });

    return this.sessionId$;
  }

  /**
   * Call this method when a user logs out to clear the session with Verisoul.
   */
  onLogout() {
    // https://docs.verisoul.ai/docs/get-session-and-reinitialize
    if (!this.isLoaded$) {
      return;
    }
    this.isLoaded$.then(() => {
      if (window === undefined || window['Verisoul'] === undefined) {
        return;
      }

      const verisoulSdk = window['Verisoul'] as VerisoulSdk;

      verisoulSdk
        .reinitialize()
        .then(() => {
          this.sessionId$.next(undefined);
        })
        .catch((e: any) => {
          console.debug('Error reinitializing Verisoul', e);
        });
    });
  }
}
